import { PageHeader } from "antd";
import React from "react";
import logo from "./cfgninga-logo-horizontal.png";

// displays a page header

export default function Header({link}) {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <img src={logo} 
        ></img>

      <PageHeader 

        style={{ cursor: "pointer" }}
              />
    </a>
  );
}


