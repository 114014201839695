import { Button, Card, DatePicker, Divider, Input, Radio } from "antd";
import React, { useState, useEffect } from "react";
import { Address } from "../components";
import { ethers } from "ethers";

import jarAbi from "../contracts/jarAbi.json";
import ltTokenAbi from "../contracts/lttokenabi.json";
import nftTokenAbi from "../contracts/nftTokenAbi.json";
import ERC20 from "../contracts/ERC20.json";
import Web3 from "web3";


export default function Lock({
  purpose,
  address,
  mainnetProvider,
  localProvider,
  yourLocalBalance,
  price,
  tx,
  readContracts,
  writeContracts,
  userSigner,
}) {
  const [amount, setAmount] = useState("Enter Amount to Lock");
  const [ltTokenAddress, setltTokenAddress] = useState("0xcb186051DD62914B6dFc56c257823bfDA20DbEe6");
  const [date, setDate] = useState(0);
  const [approveResponse, setApproveResponse] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [tokenInfo, updateTokenInfo] = useState("fungible");
  const [nftId, setNftId] = useState(0);
  

  const jarAddress = "0xf320686F4b1860334345cb219B981F05a4c49b75";
  const bondAddress = "0x8b46a0b71b4fd51882f4d8bde6c67c1ac0778088";
  const donationAddress = "0xAe50A9404e79160c51e7266021B644B906972B3F";
  const jarContract = new ethers.Contract(jarAddress, jarAbi, userSigner);

  var ltTokenContract = new ethers.Contract(ltTokenAddress, ERC20, userSigner);

  const ltTokenBalance = "";


  const getTokenBalanceOne = async () => {
    if(ltTokenAddress&&userSigner) {
      let address = await userSigner.getAddress();     
      let balanceOne = await ltTokenContract.balanceOf(address);
      let oneQuarter = (balanceOne * 25 / 100).toString();
            
      console.log(oneQuarter);
      setAmount(oneQuarter);
      
    }};

  const getTokenBalanceTwo = async () => {
    if(ltTokenAddress&&userSigner) {
      let address = await userSigner.getAddress()      
      let balanceTwo = await ltTokenContract.balanceOf(address)
      let oneHalf = (balanceTwo * 50 / 100).toString();
      
      console.log(oneHalf);
      setAmount(oneHalf);
    }}

  const getTokenBalanceThree = async () => {
    if(ltTokenAddress&&userSigner) {
      let address = await userSigner.getAddress()      
      let balanceThree = await ltTokenContract.balanceOf(address)
      let threeQuarter = (balanceThree * 75 / 100).toString();
      
      console.log(threeQuarter);
      
      setAmount(threeQuarter);
    }    
  };

    
  const getTokenBalanceMax = async () => {
    if(ltTokenAddress&&userSigner) {
      let address = await userSigner.getAddress();      
      let balance = await ltTokenContract.balanceOf(address);
      let balanceMax = (balance);
               
      console.log(balanceMax);
      setAmount(balanceMax);
      
    }};


    
  return (
    <div>
      <div style={{ backgroundColor: 'rgba(201, 36, 36, .7)', border: "1px solid #fff", borderRadius: 15, padding: 20, width: 600, margin: "auto", marginTop: 64}}>
        <h1>Lock Your Tokens</h1>

        <Radio.Group defaultValue="fungible" buttonStyle="solid" style={{ marginTop: 16 }} onChange={e => {
            updateTokenInfo(e.target.value);
            setErrorMessage("");
          }}>
          <Radio.Button style={{marginRight: 15 }} value="fungible" >Token or LP</Radio.Button>
          <Radio.Button value="non_fungible">&nbsp;&nbsp;&nbsp;NFT&nbsp;&nbsp;&nbsp;</Radio.Button>
        </Radio.Group>

        <div>
          <h4 style={{ marginTop: 24 }}>Please enter contract or LP address</h4>
          <div style={{ margin: 8 }}>
            <Input style={{ 
                  textAlign: 'center', 
                  width: '75%',
                  backgroundColor: 'rgba(201, 36, 36, .5)',
                  border: "1px solid #fff", 
                  borderRadius: 15, 
                  width: '75%', 
                  padding: 12, 
                  margin: "auto" 
                }}
            placeholder={'Enter Contract or Pair Address'}
              onChange={e => {
                setltTokenAddress(e.target.value);
                }
                
            }
            />
          </div>
        </div>


        <div hidden={tokenInfo === "non_fungible"}>
          <h4 style={{ marginTop: 16 }}>Amount to lock</h4>
          <div style={{ margin: 8 }}>
            <Input style={{ 
                  textAlign: 'center', 
                  width: '75%',
                  backgroundColor: 'rgba(201, 36, 36, .5)',
                  border: "1px solid #fff", 
                  borderRadius: 15, 
                  width: '75%', 
                  padding: 12, 
                  margin: "auto" 
                }}
              placeholder={amount}
              onChange={e => {
                setAmount(Web3.utils.fromWei((e.target.value), 'wei'));
              }}
            />
          </div>
        </div>

        <div hidden={tokenInfo !== "non_fungible"}>
          <h4 style={{ marginTop: 16 }}>NFT ID</h4>
          <div style={{ margin: 8 }}>
            <Input style={{ 
                  textAlign: 'center', 
                  width: '75%',
                  backgroundColor: 'rgba(201, 36, 36, .5)',
                  border: "1px solid #fff", 
                  borderRadius: 15, 
                  width: '75%', 
                  padding: 12, 
                  margin: "auto" 
                }}
            placeholder={'Please Enter the Token ID of your NFT'}
              onChange={e => {
                setNftId(e.target.value);
              }}
            />
          </div>
        </div>


        <div hidden={tokenInfo !== "fungible"}>
          <Button
          type="primary"
            style={{ marginTop: 8, paddingLeft: 10, paddingRight: 10, marginRight: 15 }}
            onClick={async () => { getTokenBalanceOne()



              //var _newBalanceOne = (web3.utils.toBN(_balanceOne).toString());
              
     
          }}
          >

         &nbsp;&nbsp; 25% &nbsp;&nbsp;&nbsp;
          </Button>



          <Button
          type="primary"
            style={{ marginTop: 8, paddingLeft: 10, paddingRight: 10, marginRight: 15 }}
            onClick={async () => { getTokenBalanceTwo()
              
              //var _newBalanceTwo = (web3.utils.toWei(String(_balanceTwo), 'wei'));

            }}
          >
         &nbsp;&nbsp; 50% &nbsp;&nbsp;&nbsp;
          </Button>
          
          <Button
          type="primary"
            style={{ marginTop: 8, paddingLeft: 10, paddingRight: 10, marginRight: 15 }}
            onClick={async () => { getTokenBalanceThree()

              //web3.utils.toBN(amount).toString()
              //var _newBalanceThree = (web3.utils.toWei(String(_balanceThree), 'wei'));

            }}
          >
         &nbsp;&nbsp; 75% &nbsp;&nbsp;&nbsp;
          </Button>
        

          <Button
          type="primary"
            style={{ marginTop: 8, paddingLeft: 10, paddingRight: 10, marginRight: 15 }}
              onClick={async () => { getTokenBalanceMax()
                
                
           }}
          >

            
         &nbsp;&nbsp; Max &nbsp;&nbsp;&nbsp;
          </Button></div>



        <h4 style={{ marginTop: 24 }}>Please select unlock date and time</h4>

        <div style={{ margin: 8 }}>
          <div style={{ marginTop: 2 }}>
            <DatePicker 
            style={{ 
              textAlign: 'center', 
              width: '75%',
              backgroundColor: 'rgba(201, 36, 36, .5)',
              border: "1px solid #fff", 
              borderRadius: 15, 
              width: '75%', 
              padding: 12, 
              margin: "auto" 
            }}
            showTime onChange={(dateMomentObject,dateString) => {
              setDate(dateMomentObject.unix());
              console.log(dateMomentObject.unix());
              // alert(date);
            }} />
          </div>
            <Divider />
          <p>{errorMessage}</p>

          
          <Button type="primary"
            style={{ marginTop: 8, marginRight: 15 }}
            onClick={async () => {
              
              const tokenContract = (tokenInfo === "fungible") ? new ethers.Contract(ltTokenAddress, ltTokenAbi, userSigner) : new ethers.Contract(ltTokenAddress, nftTokenAbi, userSigner);
                 try {
                  if(tokenInfo == "fungible") {
                    await tokenContract.approve(jarAddress, amount);
                  } else {
                    await tokenContract.approve(jarAddress, nftId);
                  }
                  setErrorMessage("Successfully Approved");
                 } catch (e) {
                  setErrorMessage(e.toString());
                 }console.log(amount);
            }}
          >
            &nbsp;Approve
          </Button>
          <Button
          type="primary"
            style={{ marginTop: 8, paddingLeft: 10, paddingRight: 10 }}
            onClick={async () => {
              
              console.log("Stated Amount = " + amount);
              if (errorMessage != "Successfully Approved") {
                setErrorMessage("Please Approve First");
              }
              else {
                try{
                const lockResponse = (tokenInfo === "non_fungible") ? await jarContract.lockNFT(ltTokenAddress, nftId, date) : await jarContract.lockFT(ltTokenAddress, amount, date);
                window.lockResponse = lockResponse;
                if(tokenInfo == "fungible") {
                  setErrorMessage((amount) + " tokens successfully locked. Check your wallet for bond tokens issued.");
                } else {
                  setErrorMessage("NFT successfully locked. Check your wallet for bond token issued.");
                }}  catch (e) {
                  setErrorMessage(e.toString());
                }
              }
            }}
          >
         &nbsp;&nbsp;Lock &nbsp;&nbsp;&nbsp;
          </Button>
          <br></br>
        </div>
        <h4 style={{ marginTop: 16 }}>Please Wait for Confirmation in your wallet before Clicking "Lock"</h4>
      </div>
      <br></br>      We offer this service absolute free, please consider donating <Address address={donationAddress} fontSize={12}/>
    </div>
  );
}
