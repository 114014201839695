import { Button, Divider, Input, Radio } from "antd";
import React, { useState, useEffect } from "react";


import Web3 from "web3";
import { ethers } from "ethers";
import ERC20 from "../contracts/ERC20.json";
import jarAbi from "../contracts/jarAbi.json";


import { Address, Balance, Events } from "../components";

export default function Check({

  userSigner
}) {
  
 
  const [tokenAddress, setTokenAddress] = useState(false);
  const [balanceNew, setBalance] = useState();
  const [amount, setAmount] = useState();
  const [tokenInfo, updateTokenInfo] = useState("fungible");
  const [errorMessage, setErrorMessage] = useState();
  const [bondId, setBondId] = useState(false);
  const [bondInfo, setBondInfo] = useState("");
  const [tokenDecimals, setTokenDecimals] = useState([]);  
  const [tokenName, setTokenName] = useState([]); 
  const [formattedValue, setFormatted] = useState([]);
  
  
   
  const date = new Date (bondInfo.unlockTime * 1000).toLocaleString();
  const newDate = date;//

  const lockerAddress = "0xf320686F4b1860334345cb219B981F05a4c49b75";
  const donationAddress = "0xAe50A9404e79160c51e7266021B644B906972B3F";

    
  const getTokenBalance = async () => {
    if(tokenAddress&&userSigner) {
      const web3 = new Web3(window.ethereum);
      let tokenContract = new ethers.Contract(tokenAddress, ERC20, userSigner);      
      let balance = await tokenContract.balanceOf(lockerAddress);
      let newBalanceOne = ((balance));
      setBalance(newBalanceOne)
            
      let tokenMetaData = await fetch(`https://phoenixplorer.com/api?module=token&action=getToken&contractaddress=${tokenAddress}`);
      let dataJson = await tokenMetaData.json();
        console.log('Token Data', dataJson);
        console.log('Decimals', dataJson.result.decimals);
            setTokenDecimals(dataJson.result.decimals);
            setTokenName(dataJson.result.name);
        
      let formatted = newBalanceOne / (10 ** dataJson.result.decimals);
      let rounded = Number(formatted.toFixed(4));
              
              //const value = padded ? `${padded.slice(0, -dataJson.result.decimals)}.${padded.slice(-dataJson.result.decimals)}` : 0;
                           
      setFormatted(rounded);
      setBalance(formatted)
      console.log(newBalanceOne);
      console.log('Formatted Number', formatted)
    }};

    

  const getBondInfo = async () => {
    if(bondId&&userSigner) {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      let jarContract = new web3.eth.Contract(jarAbi, lockerAddress);
      let bondInfoCall = await jarContract.methods.padlocks(bondId).call();
      setBondInfo(bondInfoCall)
      //console.log('bond info call', JSON.stringify(bondInfoCall));
        
      console.log('PadLock Data', bondInfoCall);
      console.log('Contract Address', bondInfoCall.tokenAddress);
      
      let tokenMetaData = await fetch(`https://phoenixplorer.com/api?module=token&action=getToken&contractaddress=${bondInfoCall.tokenAddress}`);
      let dataJson = await tokenMetaData.json();
        
        console.log('Token Data', dataJson);
        console.log('Decimals', dataJson.result.decimals);
        
            setTokenDecimals(dataJson.result.decimals);

            setTokenName(dataJson.result.name);
        
            const padded = bondInfoCall._amount ? bondInfoCall._amount.padStart(dataJson.result.decimals, '0') : 0;

            const value = padded ? `${padded.slice(0, -dataJson.result.decimals)}.${padded.slice(-dataJson.result.decimals)}` : 0;

            const formatted = Number(value).toFixed(4); // Round to 4 decimal places
                           
              setFormatted(formatted);
          
     }};



  return (
    
    <div>
      <div style={{ backgroundColor: 'rgba(201, 36, 36, .7)', border: "1px solid #fff", borderRadius: 15, padding: 20, width: 600, margin: "auto", marginTop: 64}}>
        <h1>Check For Existing Lock</h1>


            <Radio.Group defaultValue="fungible" buttonStyle="solid" style={{ marginTop: 16 }} onChange={e => {
            updateTokenInfo(e.target.value);
            setErrorMessage("");
          }}>
          <Radio.Button style={{marginRight: 15 }} value="fungible" >Token or LP</Radio.Button>
          <Radio.Button value="non_fungible">Existing Lock</Radio.Button>
        </Radio.Group>


        <div hidden={tokenInfo === "non_fungible"}>
          <h4 style={{ marginTop: 24 }}>Please Enter Contract or LP address</h4>
          <div style={{ margin: 8 }}>
            <Input style={{ 
                  textAlign: 'center', 
                  width: '75%',
                  backgroundColor: 'rgba(201, 36, 36, .5)',
                  border: "1px solid #fff", 
                  borderRadius: 15, 
                  width: '75%', 
                  padding: 12, 
                  margin: "auto" 
                }}
            placeholder={'Enter Address'}
             onChange={e => {
              setTokenAddress(e.target.value);
             }}
             />
          </div>
          <Button
            type="primary"
            style={{ marginTop: 18 }}
            onClick={async () => { getTokenBalance()
                
         }}
          >
            Check for Lock
          </Button>
          </div>
          


            <div hidden={tokenInfo !== "non_fungible"}>
              <h4 style={{ marginTop: 24 }}>Please Enter Bond ID</h4>
              <div style={{ margin: 8 }}>
                <Input style={{ 
                  textAlign: 'center', 
                  width: '75%',
                  backgroundColor: 'rgba(201, 36, 36, .5)',
                  border: "1px solid #fff", 
                  borderRadius: 15, 
                  width: '75%', 
                  padding: 12, 
                  margin: "auto" 
                }}
                placeholder={'Bond ID'}
                onChange={e => {
                  setBondId(e.target.value);
                  console.log(bondId);
                }}
              />
                      
      </div>
      <Button
            type="primary"
            style={{ marginTop: 18 }}
            onClick={async () => { getBondInfo()
                             
                            
         }}
          >
            Check for Key Lock
          </Button>
        </div>
        



        <Divider />      
        <div align="center">

         {
          bondId?
         <div hidden={tokenInfo !== "non_fungible"}> <h2>{tokenName}:</h2>

           <h4 style={{ textAlign: 'center'}}>
            <center>



<p>Owner:</p>
<p style={{ 
  fontFamily: "Roboto, sans-serif",
  backgroundColor: 'rgba(201, 36, 36, .5)',
  border: "1px solid #fff", 
  borderRadius: 15, 
  width: '75%', 
  padding: 12, 
  margin: "auto" 
  }}>
    <Address address={bondInfo._owner} /></p><br></br>
    
<p>Token Address:</p>
<p style={{ 
  fontFamily: "Roboto, sans-serif",
  backgroundColor: 'rgba(201, 36, 36, .5)',
  border: "1px solid #fff", 
  borderRadius: 15, 
  width: '75%', 
  padding: 12, 
  margin: "auto" 
  }}>
    <Address address={bondInfo.tokenAddress} /></p><br></br>

<p>Amount Locked:</p>

<p style={{ 
  fontFamily: "Roboto, sans-serif",
  backgroundColor: 'rgba(201, 36, 36, .5)',
  border: "1px solid #fff", 
  borderRadius: 15, 
  width: '75%', 
  padding: 12, 
  margin: "auto"
  
  }}>{formattedValue}</p><br></br>

<p>Unlock Time:</p>
<p  style={{ 
  fontFamily: "Roboto, sans-serif",
  backgroundColor: 'rgba(201, 36, 36, .5)',
  border: "1px solid #fff", 
  borderRadius: 15, 
  width: '75%', 
  padding: 12, 
  margin: "auto" 
  }}
  >
    {newDate}
  </p><br></br>
  <Divider hidden={tokenInfo !== "non_fungible"}/>
  </center>
          </h4>
          </div>
          :null}
          
          </div>



          <div align="center">

{
 tokenAddress?
<div>

<h2 hidden={tokenInfo === "non_fungible"}>Currently Locked Balance: <br></br>{formattedValue}</h2>
 <br></br>
 <p hidden={tokenInfo === "non_fungible"}>Please Note this is shown as whole number, please consider the decimal places</p>
 <Divider hidden={tokenInfo === "non_fungible"}/>
  </div>
  
  :null}
  </div>



        <h2>Locker Address:</h2><br></br>
        <p style={{ 
          fontFamily: "Roboto, sans-serif",
  backgroundColor: 'rgba(201, 36, 36, .5)',
  border: "1px solid #fff", 
  borderRadius: 15, 
  width: '75%', 
  padding: 12, 
  margin: "auto" 
  }}><Address address={lockerAddress} fontSize={16} /></p>
        <br></br>
        
      </div><br></br>
      We offer this service absolute free, please consider donating <Address address={donationAddress} fontSize={12}/>
    </div>
  );
}
