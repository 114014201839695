import { useContractReader } from "eth-hooks";
import { ethers } from "ethers";
import React from "react";
import { Link } from "react-router-dom";
import { Address } from "../components";

const lockerAddress = "0xf320686F4b1860334345cb219B981F05a4c49b75";
const donationAddress = "0xAe50A9404e79160c51e7266021B644B906972B3F";

/**
 * web3 props can be passed from '../App.jsx' into your local view component for use
 * @param {*} yourLocalBalance balance on current network
 * @param {*} readContracts contracts from current chain already pre-loaded using ethers contract module. More here https://docs.ethers.io/v5/api/contract/contract/
 * @returns react component
 **/
function Home({ yourLocalBalance, readContracts }) {
  // you can also use hooks locally in your component of choice
  // in this case, let's keep track of 'purpose' variable from our contract
  const purpose = useContractReader(readContracts, "YourContract", "purpose");

  return (<div>
    <div style={{ backgroundColor: 'rgba(201, 36, 36, .7)', border: "1px solid #cccccc", borderRadius: 15, padding: 20, width: 600, margin: "auto", marginTop: 64}}>
    <br></br>
    <br></br>
        <h1>Welcome to CFG Ninja Locker!</h1><br></br>
        <p style={{alignItems: 'center'}}>When you use CFG Ninja to lock your tokens or Liquidity, you will receive a Bond NFT with the lock details embeded that will be lost during the unlock process. This bond token can be traded for your original tokens after the unlock date has passed. Bond tokens can also be sold to other users, allowing the new owner of the bond token to retrieve the corresponding locked token once the unlock date has passed.</p>
          <br/>
          <h3>Click <Link to="/lock">Locker</Link> to get started.</h3>
          <br></br>
          <br></br>
          <h3>Please be sure to exclude <Address address={lockerAddress} fontSize={16} /> from all fees and rewards!</h3>
          <br></br>
          
    </div>
    <p style={{aligntItems: 'center', marginTop: 30}}></p>We offer this service absolute free, please consider donating <Address address={donationAddress} fontSize={12} />
    </div>
    
  );
}

export default Home;
