import { Button, Divider, Input, Radio } from "antd";
import React, { useState } from "react";
import { utils } from "ethers";
import { SyncOutlined } from "@ant-design/icons";
import { ethers } from "ethers";
import { Address, Balance, Events } from "../components";
import jarAbi from "../contracts/jarAbi.json";
import bondAbi from "../contracts/bondAbi.json";
import ltTokenAbi from "../contracts/lttokenabi.json";
import nftTokenAbi from "../contracts/nftTokenAbi.json";
import ERC20 from "../contracts/ERC20.json";
import Web3 from "web3";

export default function Unlock({
  purpose,
  address,
  mainnetProvider,
  localProvider,
  yourLocalBalance,
  price,
  tx,
  readContracts,
  writeContracts,
  userSigner,
}) {
  const [btAddress, setBtAddress] = useState("Please enter the ID of the ERC1155 Key");
  const [amount, setAmount] = useState("Enter Amount to Unlock");
  const [errorMessage, setErrorMessage] = useState("");
  const [tokenInfo, updateTokenInfo] = useState("fungible");
  const [bondBalance, setBondBalance] = useState();
  const [bondInfo, setBondInfo] = useState("");

  const web3 = new Web3(window.ethereum);
  
  const jarAddress = "0xf320686F4b1860334345cb219B981F05a4c49b75";
  const bondAddress = "0x8b46a0b71b4fd51882f4d8bde6c67c1ac0778088";
  const donationAddress = "0xAe50A9404e79160c51e7266021B644B906972B3F";
  let jarContract = new ethers.Contract(jarAddress, jarAbi, userSigner);
  let bondContract = new ethers.Contract(bondAddress, bondAbi, userSigner);

  const getBondBalanceMax = async () => {
    if(tokenInfo !== "non_fungible") {
          
      let bondInfoCall = await jarContract.padlocks(btAddress);
     
      setBondInfo(bondInfoCall)
      setBondBalance(bondInfoCall.tokenAddress)
      console.log("Token Address:", bondInfoCall.tokenAddress);
      console.log("Owner:", bondInfoCall._owner);
      setAmount(bondInfoCall._amount);
      console.log("Amount:", bondInfoCall._amount);
    }
  }

  const getBondBalanceOne = async () => {
    if(tokenInfo !== "non_fungible") {
      
      
      
      let bondInfoCall = await jarContract.padlocks(btAddress);
      
      setBondInfo(bondInfoCall)
      setBondBalance(bondInfoCall.tokenAddress)
      console.log("Token Address:", bondInfoCall.tokenAddress);
      console.log("Owner:", bondInfoCall._owner);
      let oneQuarter = Math.floor(bondInfoCall._amount / 4);
      setAmount(oneQuarter);
    }
  }

  const getBondBalanceTwo = async () => {
    if(tokenInfo !== "non_fungible") {
      
      
      
      let bondInfoCall = await jarContract.padlocks(btAddress);
      
      setBondInfo(bondInfoCall)
      setBondBalance(bondInfoCall.tokenAddress)
      console.log("Token Address:", bondInfoCall.tokenAddress);
      console.log("Owner:", bondInfoCall._owner);
      let twoQuarters = Math.floor(bondInfoCall._amount / 2);
      setAmount(twoQuarters);
    }
  }

  const getBondBalanceThree = async () => {
    if(tokenInfo !== "non_fungible") {
      
      
      
      let bondInfoCall = await jarContract.padlocks(btAddress);
      
      setBondInfo(bondInfoCall)
      setBondBalance(bondInfoCall.tokenAddress)
      console.log("Token Address:", bondInfoCall.tokenAddress);
      console.log("Owner:", bondInfoCall._owner);
      let threeQuarters = Math.floor(bondInfoCall._amount * 3 / 4);
      setAmount(threeQuarters);
    }
  }


  

  return (
    <div>
      {/*
        ⚙️ Here is an example UI that displays and sets the purpose in your smart contract:
      */}
      <div style={{ backgroundColor: 'rgba(201, 36, 36, .7)', border: "1px solid #fff", borderRadius: 15, padding: 20, width: 600, margin: "auto", marginTop: 64}}>
        <h1>Unlock Your Tokens</h1>

        <Radio.Group defaultValue="fungible" buttonStyle="solid" style={{ marginTop: 16 }} onChange={e => {
          updateTokenInfo(e.target.value);
          setErrorMessage("");
        }}>
          <Radio.Button value="fungible" style={{ marginRight: 15 }}>Token or LP</Radio.Button>
          <Radio.Button value="non_fungible">&nbsp;&nbsp;&nbsp;NFT&nbsp;&nbsp;&nbsp;</Radio.Button>
        </Radio.Group>

        <h4 style={{ marginTop: 24 }}>LockFT ID</h4>
        <div style={{ margin: 8 }}>
          <Input style={{ 
                  textAlign: 'center', 
                  width: '75%',
                  backgroundColor: 'rgba(201, 36, 36, .5)',
                  border: "1px solid #fff", 
                  borderRadius: 15, 
                  width: '75%', 
                  padding: 12, 
                  margin: "auto" 
                }}
          placeholder={btAddress}
            onChange={e => {
              setBtAddress(e.target.value);
            }}
          />
        </div>

        <div hidden={tokenInfo === "non_fungible"}>
          <h4 style={{ marginTop: 24 }}>Amount to withdrawal </h4>
          <div style={{ margin: 8 }}>
            <Input style={{ 
                  textAlign: 'center', 
                  width: '75%',
                  backgroundColor: 'rgba(201, 36, 36, .5)',
                  border: "1px solid #fff", 
                  borderRadius: 15, 
                  width: '75%', 
                  padding: 12, 
                  margin: "auto" 
                }}
            placeholder={amount}
              onChange={e => {
                setAmount(Web3.utils.fromWei(String(e.target.value), 'wei'));
              }}
            />
          </div>
          <div hidden={tokenInfo !== "fungible"}>
          
          
          <Button
          type="primary"
            style={{ marginTop: 8, paddingLeft: 10, paddingRight: 10, marginRight: 15 }}
            onClick={async () => { getBondBalanceOne()

          
          }}
          >
         &nbsp;&nbsp; 25% &nbsp;&nbsp;&nbsp;
          </Button>



          <Button
          type="primary"
            style={{ marginTop: 8, paddingLeft: 10, paddingRight: 10, marginRight: 15 }}
            onClick={async () => { getBondBalanceTwo()


            }}
          >
         &nbsp;&nbsp; 50% &nbsp;&nbsp;&nbsp;
          </Button>
          
          <Button
          type="primary"
            style={{ marginTop: 8, paddingLeft: 10, paddingRight: 10, marginRight: 15 }}
            onClick={async () => { getBondBalanceThree()
              
            }}
          >
         &nbsp;&nbsp; 75% &nbsp;&nbsp;&nbsp;
          </Button>
        

          <Button
          type="primary"
            style={{ marginTop: 8, paddingLeft: 10, paddingRight: 10, marginRight: 15 }}
              onClick={async () => {getBondBalanceMax();
                
               
           }}
          >
         &nbsp;&nbsp; Max &nbsp;&nbsp;&nbsp;
          </Button></div>
        </div>
            <Divider />

            
            <p>{errorMessage}</p>
          <Button type="primary"
            style={{ marginTop: 8, marginRight: 15 }}
            onClick={async () => {
              const tokenContract = (tokenInfo === "fungible") ? new ethers.Contract(bondAddress, bondAbi, userSigner) : new ethers.Contract(bondAddress, bondAbi, userSigner);   
              try {

                  const unlockResponse = await tokenContract.setApprovalForAll(jarAddress, true);
                     window.unlockResponse = unlockResponse;
                   setErrorMessage("Successfully Approved");
                  } catch (e) {
                   setErrorMessage(e.toString());
             } }}
          >
            Approve
          </Button>
          <Button  
            type="primary"
            style={{ marginTop: 8 }}
            onClick={async () => {
              let weiAmount = Web3.utils.fromWei(String(amount), 'wei');
              
                if (errorMessage != "Successfully Approved") {
                    setErrorMessage("Please Approve First");
                  }
                  else {
                // const provider = new ethers.providers.Web3Provider(window)
                //  const name = await jarContract.bond();
                //  alert(name);

                try {
                    setErrorMessage("unlocking");
                    console.log("Token Balance to unlock " + amount);
                    console.log("Format of amount " + typeof(amount));
                    console.log("BondId to unlock " + btAddress);
                    const unlockResponse = (tokenInfo === "fungible") ? await jarContract.unlockFT(btAddress, weiAmount) : await jarContract.unlockNFT(btAddress);
                    window.unlockResponse = unlockResponse;
                    if(tokenInfo == "fungible") {
                      setErrorMessage("Request to unlock " + (amount) + " tokens sent. Please check your wallet for status.")
                    } else {
                      setErrorMessage("Request to unlock NFT sent. Please check your wallet for status.")
                    }
                      // setErrorMessage(amount + " tokens successfully unlocked. Check your wallet for liquidity tokens returned.");
                 } catch (e) {
                     window.errorMessageMine = e;
                     if (e.data && e.data.message) {
                        setErrorMessage(e.data.message);
                     } else {
                         setErrorMessage("error");
                     }

                 }
                }
            }}
          >
           &nbsp;&nbsp; Unlock&nbsp;&nbsp;
          </Button>
          <br></br>
          <br></br>
          <h4 style={{ marginTop: 16 }}>Please Wait for Confirmation in your wallet before Clicking "Unlock"</h4>
          <br></br>
          </div><br></br>
      We offer this service absolute free, please consider donating <Address address={donationAddress} fontSize={12}/>
    </div>
  );
}
